import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Navbar from './components/NavDesktop/Navbar';
import Footer from './components/FooterDesktop/Footer';
import FooterMobile from './components/mobile/FooterMobile/footerMobile';
import HomePage from './components/HomePage';
import HistoryPage from './components/HistoryPage';
import Hotels from './components/Hotels';
import Spotify from './components/Spotify';
import Actualites from './components/NosActualites';
import Mentions from './components/mentions';
import './App.css';
import useFullPageScroll from './hooks/useFullPageScroll';
import BookingButton from './components/BookingButton/bookingButton';
import { FilterProvider } from './FilterContext';
import TagManager from 'react-gtm-module';
import HomePageEN from './components/HomePageEN';
import HotelsEN from './components/HotelsEN';
import HistoryPageEN from './components/HistoryPageEN';
import ActualitesEN from './components/NosActualitesEN';
import SpotifyEN from './components/SpotifyEN';
import MentionsEN from './components/mentionsEN';
import Test from './components/Test';
import BookingModule from './components/ModuleResa/ModuleResa'; // Import du module de réservation

const App = () => {
  const [language, setLanguage] = useState('fr');
  const [contentState, setContentState] = useState({
    home: { landing: {}, textBlock: {}, footer: { recrutement: '', instagram: '' }, other: {} },
    history: { title1: '', text1: '', text2: '' },
    hotels: {},
    actualites: {},
    mentions: {},
  });

  const [buttonVisibility, setButtonVisibility] = useState('show');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [transparent, setTransparent] = useState(0);
  const [currentSection, setCurrentSection] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);  // Ajout de l'état pour gérer l'ouverture du module

  const openBookingModule = () => {
    setIsModalOpen(true);  // Ouvre le module
  };

  const closeBookingModule = () => {
    setIsModalOpen(false);  // Ferme le module
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 1024);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const LocationUpdater = () => {
    const location = useLocation();

    useEffect(() => {
      const lang = location.pathname.startsWith('/en') ? 'en' : 'fr';
      setLanguage(lang);

      window.scrollTo(0, 0);
    }, [location.pathname]);

    useEffect(() => {
      const initialPage = location.pathname.includes('histoire') ? 'history' : 'home';
      if (contentState[language] && contentState[language][initialPage]) {
        setContentState(contentState[language][initialPage]);
      }
    }, [location.pathname]);

    return null;
  };

  useFullPageScroll(setTransparent, setCurrentSection);

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-TQMCDF2',
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  useEffect(() => {
    const footer = document.querySelector('footer');

    if (!footer) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.target === footer) {
            setButtonVisibility(entry.isIntersecting ? 'hidden' : 'show');
          }
        });
      },
      {
        root: null,
        threshold: 0.1,
      }
    );

    observer.observe(footer);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <FilterProvider>
      <Router>
        <LocationUpdater />
        <Navbar
          changePage={setLanguage}
          isTransparent={transparent}
          langue={language}
        />
        <Routes>
          <Route path="/" element={<HomePage content={contentState.home} />} />
          <Route path="/en" element={<HomePageEN content={contentState.home} />} />
          <Route path="/histoire" element={<HistoryPage content={contentState.history} language={language} />} />
          <Route path="/en/histoire" element={<HistoryPageEN content={contentState.history} language={language} />} />
          <Route path="/les-hotels" element={<Hotels langue = { language }  content={contentState.hotels} />} />
          <Route path="/en/les-hotels" element={<HotelsEN langue={language} content={contentState.hotels} />} />
          <Route path="/nos-actualites" element={<Actualites content={contentState.actualites} language={language} />} />
          <Route path="/en/nos-actualites" element={<ActualitesEN content={contentState.actualites} language={language} />} />
          <Route path="/la-musique" element={<Spotify content={contentState.actualites} language={language} />} />
          <Route path="/en/la-musique" element={<SpotifyEN content={contentState.actualites} language={language} />} />
          <Route path="/mentions" element={<Mentions content={contentState.mentions} language={language} />} />
          <Route path="/en/mentions" element={<MentionsEN content={contentState.mentions} language={language} />} />
          <Route path="/test" element={<Test content={contentState.mentions} language={language} />} />
        </Routes>

        {/* Le module de réservation s'affiche ici lorsque isModalOpen est true */}
        {isModalOpen && <BookingModule onClose={closeBookingModule} langue={language}/>}

        {isMobile ? (
          <footer className="section">
            <FooterMobile changeLanguage={setLanguage} content={contentState.footer} />
          </footer>
        ) : (
          <footer className="section">
            <Footer changeLanguage={setLanguage} activeLanguage={language} content={contentState.footer} />
          </footer>
        )}

        {/* Le bouton est conditionnellement rendu : il disparait quand le module est ouvert */}
        {buttonVisibility && !isModalOpen && (
          <BookingButton language={language}
            className={`reserve-button ${buttonVisibility} ${currentSection === 0 ? 'landing' : ''}`}
            onClick={openBookingModule}  // Associe la fonction pour ouvrir le module
          />
        )}
      </Router>
    </FilterProvider>
  );
};

export default App;
