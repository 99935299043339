import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import './hotelslanding.css';
import content from '../../content.json';
import IMAGE1 from '../../assets/Hotels/HOTELHANA.jpg';
import IMAGE2 from '../../assets/Hotels/CABH.jpg';
import IMAGE3 from '../../assets/Hotels/LaPonche.jpg';
import IMAGE4 from '../../assets/Hotels/MONSIEURARISTIDE.jpg';
import IMAGE5 from '../../assets/Hotels/ACADEMIES.jpg';
import IMAGE6 from '../../assets/Hotels/MEGEVE.jpg';
import IMAGE7 from '../../assets/Hotels/BARBIZON.jpg';
import IMAGE8 from '../../assets/Hotels/BUSPALLADIUM.jpg';
import IMAGE9 from '../../assets/Hotels/CADET.jpg';
import IMAGE10 from '../../assets/Hotels/GEORGE.jpg';
import IMAGE11 from '../../assets/Hotels/SAINTONGE.jpg';
import { useFilter } from '../../FilterContext';

const urlSite = [
    "https://hotelhana-paris.com/",
    "https://capdantibes-beachhotel.com/",
    "https://laponche.com/",
    "https://www.monsieuraristide.com/",
    "https://hoteldesacademies.fr/",
    "https://chaletsaintgeorges.com/fr",
    "https://www.lafoliebarbizon.com/",
    "https://www.buspalladium.com/",
    "https://www.monsieurcadet.com/",
    "https://www.monsieurgeorge.com/",
    "https://maisonsaintonge.com/",
];

const HotelsLanding = ({ langue }) => {
    const { selectedFilter } = useFilter();
    const [paused, setPaused] = useState(false);
    const carouselRef = useRef(null);
    const startX = useRef(0);
    const scrollLeft = useRef(0);
    const [visibleItems, setVisibleItems] = useState([]);
    const duplicatedItems = [...visibleItems, ...visibleItems, ...visibleItems];
    const duration = Math.max(100, duplicatedItems.length * 10);
    const carouselWidth = `calc(${duplicatedItems.length} * 100%)`;
    const itemWidth = '439px';

    // Utilisation de useMemo pour éviter le recalcul de items à chaque rendu
    const items = useMemo(() => [
        {
            title: langue === 'fr' ? content.hotels.fr.hana.nom : content.hotels.en.hana.nom,
            address: langue === 'fr' ? content.hotels.fr.hana.adresse : content.hotels.en.hana.adresse,
            image: IMAGE1,
            subtitle: langue === 'fr' ? content.hotels.fr.hana.titre : content.hotels.en.hana.titre,
            text: langue === 'fr' ? content.hotels.fr.hana.texte : content.hotels.en.hana.texte,
            category: 'paris'
        },
        {
            title: langue === 'fr' ? content.hotels.fr.antibes.nom : content.hotels.en.antibes.nom,
            address: langue === 'fr' ? content.hotels.fr.antibes.adresse : content.hotels.en.antibes.adresse,
            image: IMAGE2,
            subtitle: langue === 'fr' ? content.hotels.fr.antibes.titre : content.hotels.en.antibes.titre,
            text: langue === 'fr' ? content.hotels.fr.antibes.texte : content.hotels.en.antibes.texte,
            category: 'ailleurs'
        },
        {
            title: langue === 'fr' ? content.hotels.fr.laponche.nom : content.hotels.en.laponche.nom,
            address: langue === 'fr' ? content.hotels.fr.laponche.adresse : content.hotels.en.laponche.adresse,
            image: IMAGE3,
            subtitle: langue === 'fr' ? content.hotels.fr.laponche.titre : content.hotels.en.laponche.titre,
            text: langue === 'fr' ? content.hotels.fr.laponche.texte : content.hotels.en.laponche.texte,
            category: 'ailleurs'
        },
        {
            title: langue === 'fr' ? content.hotels.fr.aristide.nom : content.hotels.en.aristide.nom,
            address: langue === 'fr' ? content.hotels.fr.aristide.adresse : content.hotels.en.aristide.adresse,
            image: IMAGE4,
            subtitle: langue === 'fr' ? content.hotels.fr.aristide.titre : content.hotels.en.aristide.titre,
            text: langue === 'fr' ? content.hotels.fr.aristide.texte : content.hotels.en.aristide.texte,
            category: 'paris'
        },
        {
            title: langue === 'fr' ? content.hotels.fr.academie.nom : content.hotels.en.academie.nom,
            address: langue === 'fr' ? content.hotels.fr.academie.adresse : content.hotels.en.academie.adresse,
            image: IMAGE5,
            subtitle: langue === 'fr' ? content.hotels.fr.academie.titre : content.hotels.en.academie.titre,
            text: langue === 'fr' ? content.hotels.fr.academie.texte : content.hotels.en.academie.texte,
            category: 'paris'
        },
        {
            title: langue === 'fr' ? content.hotels.fr.chalet.nom : content.hotels.en.chalet.nom,
            address: langue === 'fr' ? content.hotels.fr.chalet.adresse : content.hotels.en.chalet.adresse,
            image: IMAGE6,
            subtitle: langue === 'fr' ? content.hotels.fr.chalet.titre : content.hotels.en.chalet.titre,
            text: langue === 'fr' ? content.hotels.fr.chalet.texte : content.hotels.en.chalet.texte,
            category: 'ailleurs'
        },
        {
            title: langue === 'fr' ? content.hotels.fr.barbizon.nom : content.hotels.en.barbizon.nom,
            address: langue === 'fr' ? content.hotels.fr.barbizon.adresse : content.hotels.en.barbizon.adresse,
            image: IMAGE7,
            subtitle: langue === 'fr' ? content.hotels.fr.barbizon.titre : content.hotels.en.barbizon.titre,
            text: langue === 'fr' ? content.hotels.fr.barbizon.texte : content.hotels.en.barbizon.texte,
            category: 'ailleurs'
        },
        {
            title: langue === 'fr' ? content.hotels.fr.paladium.nom : content.hotels.en.paladium.nom,
            address: langue === 'fr' ? content.hotels.fr.paladium.adresse : content.hotels.en.paladium.adresse,
            image: IMAGE8,
            subtitle: langue === 'fr' ? content.hotels.fr.paladium.titre : content.hotels.en.paladium.titre,
            text: langue === 'fr' ? content.hotels.fr.paladium.texte : content.hotels.en.paladium.texte,
            category: 'paris'
        },
        {
            title: langue === 'fr' ? content.hotels.fr.cadet.nom : content.hotels.en.cadet.nom,
            address: langue === 'fr' ? content.hotels.fr.cadet.adresse : content.hotels.en.cadet.adresse,
            image: IMAGE9,
            subtitle: langue === 'fr' ? content.hotels.fr.cadet.titre : content.hotels.en.cadet.titre,
            text: langue === 'fr' ? content.hotels.fr.cadet.texte : content.hotels.en.cadet.texte,
            category: 'paris'
        },
        {
            title: langue === 'fr' ? content.hotels.fr.george.nom : content.hotels.en.george.nom,
            address: langue === 'fr' ? content.hotels.fr.george.adresse : content.hotels.en.george.adresse,
            image: IMAGE10,
            subtitle: langue === 'fr' ? content.hotels.fr.george.titre : content.hotels.en.george.titre,
            text: langue === 'fr' ? content.hotels.fr.george.texte : content.hotels.en.george.texte,
            category: 'paris'
        },
        {
            title: langue === 'fr' ? content.hotels.fr.saintonge.nom : content.hotels.en.saintonge.nom,
            address: langue === 'fr' ? content.hotels.fr.saintonge.adresse : content.hotels.en.saintonge.adresse,
            image: IMAGE11,
            subtitle: langue === 'fr' ? content.hotels.fr.saintonge.titre : content.hotels.en.saintonge.titre,
            text: langue === 'fr' ? content.hotels.fr.saintonge.texte : content.hotels.en.saintonge.texte,
            category: 'paris'
        },
    ], [langue]);  // Recalculer 'items' seulement si 'langue' change

    useEffect(() => {
        const filterItems = () => {
            if (selectedFilter === 'Tous') {
                setVisibleItems(items);
            } else {
                const filteredItems = items.filter(item => item.category === selectedFilter.toLowerCase());
                setVisibleItems(filteredItems);
            }
        };

        filterItems();
    }, [selectedFilter, items]);  // 'items' est mémorisé, donc cette dépendance est nécessaire

    const handleMouseDown = (e) => {
        startX.current = e.pageX - carouselRef.current.offsetLeft;
        scrollLeft.current = carouselRef.current.scrollLeft;
        setPaused(true);
        carouselRef.current.addEventListener('mousemove', handleMouseMove);
        carouselRef.current.addEventListener('mouseup', handleMouseUp);
        carouselRef.current.addEventListener('mouseleave', handleMouseUp);
    };

    const handleMouseMove = (e) => {
        const x = e.pageX - carouselRef.current.offsetLeft;
        const walk = (x - startX.current) * 2;
        carouselRef.current.scrollLeft = scrollLeft.current - walk;

        // Logique de défilement infini
        if (carouselRef.current.scrollLeft <= 0) {
            carouselRef.current.scrollLeft = carouselRef.current.scrollWidth / 3;
        } else if (carouselRef.current.scrollLeft >= carouselRef.current.scrollWidth / 3) {
            carouselRef.current.scrollLeft = 0;
        }
    };

    const handleMouseUp = () => {
        carouselRef.current.removeEventListener('mousemove', handleMouseMove);
        carouselRef.current.removeEventListener('mouseup', handleMouseUp);
        carouselRef.current.removeEventListener('mouseleave', handleMouseUp);
    };

    const handleImageMouseDown = (e) => {
        e.preventDefault();
    };

    const handleScroll = useCallback(() => {
        const carousel = carouselRef.current;
        if (carousel.scrollLeft >= carousel.scrollWidth / 3) {
            carousel.scrollLeft = 0; // Revenir au début
        } else if (carousel.scrollLeft <= 0) {
            carousel.scrollLeft = carousel.scrollWidth / 3; // Aller à la fin
        }
    }, []);

    const handleWheel = useCallback((e) => {
        e.preventDefault(); // Empêche le comportement par défaut de défilement de la page

        // Utilisez deltaX pour le défilement horizontal
        const delta = e.deltaX || e.deltaY;
        carouselRef.current.scrollLeft += delta * 0.5; // Ajustez ce facteur selon vos besoins

        // Logique pour assurer un défilement infini
        if (carouselRef.current.scrollLeft <= 0) {
            carouselRef.current.scrollLeft = carouselRef.current.scrollWidth / 3;
        } else if (carouselRef.current.scrollLeft >= carouselRef.current.scrollWidth / 3) {
            carouselRef.current.scrollLeft = 0;
        }
    }, []);

    useEffect(() => {
        const carousel = carouselRef.current;
        if (carousel) {
            carousel.addEventListener('scroll', handleScroll);
            carousel.addEventListener('wheel', handleWheel); // Ajout de l'écouteur pour le défilement
            return () => {
                carousel.removeEventListener('scroll', handleScroll);
                carousel.removeEventListener('wheel', handleWheel); // Nettoyage de l'écouteur
            };
        }
    }, [handleScroll, handleWheel]);

    return (
        <div
            className="carouselHotelsLanding"
            onMouseEnter={() => setPaused(true)}
            onMouseLeave={() => setPaused(false)}
            ref={carouselRef}
            onMouseDown={handleMouseDown}
            style={{ overflow: 'hidden', cursor: paused ? 'grab' : 'auto' }}
        >
            <div
                className={`carousel-inner ${paused ? 'paused' : ''}`}
                style={{ width: carouselWidth, animationDuration: `${duration}s`, display: 'flex' }}
            >
                {duplicatedItems.map((item, index) => {
                    const originalIndex = items.findIndex(i => i.title === item.title);
                    const isVisible = visibleItems.some(visibleItem => visibleItem.title === item.title);

                    return (
                        <div
                            className="carousel-item"
                            key={index}
                            style={{
                                width: itemWidth,
                                opacity: isVisible ? 1 : 0,
                                pointerEvents: isVisible ? 'auto' : 'none',
                                transition: 'opacity 0.5s ease, margin 0.5s ease',
                                margin: isVisible ? '0 10px' : '0',
                                display: isVisible ? 'flex' : 'none',
                            }}
                        >
                            <a
                                href={urlSite[originalIndex]}
                                target="_blank"
                                rel="noopener noreferrer"
                                className='feuillet'
                                style={{ display: 'flex', flexDirection: 'column', textDecoration: 'none', color: 'inherit' }}
                            >
                                <div className='top'>
                                    <p className='title'>{item.title}</p>
                                    <p className='address'>{item.address}</p>
                                </div>
                                <img
                                    src={item.image}
                                    alt={item.title}
                                    onMouseDown={handleImageMouseDown}
                                />
                                <div className='bottom'>
                                    <p>{item.subtitle}</p>
                                    <p className='texte'>{item.text}</p>
                                    <p className='voyager'>{langue === 'fr' ? 'Voyager' : 'Travel'}</p>
                                </div>
                            </a>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default HotelsLanding;
