import React, { useRef, useEffect } from 'react';
import Slider from 'react-slick';
import './landingspotify.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const playlists = [
  { src: "https://open.spotify.com/embed/playlist/7zBJcELDGLG1VOCT8tOG5Q?utm_source=generator", title: "Playlist 1" },
  { src: "https://open.spotify.com/embed/playlist/4P1VCkVQyZfdhRZE0pkrR0?utm_source=generator", title: "Playlist 2" },
  { src: "https://open.spotify.com/embed/playlist/17xwHe4r6YWbS0Cu7pm8PD?utm_source=generator", title: "Playlist 3" },
  { src: "https://open.spotify.com/embed/playlist/44VT9sDj6aPxPdvgtmrgz2?utm_source=generator", title: "Playlist 4" },
  { src: "https://open.spotify.com/embed/playlist/4fuoSGsXUc93gCG3CgAbhS?utm_source=generator", title: "Playlist 5" },
  { src: "https://open.spotify.com/embed/playlist/4fbULkvkNciMNLJRHv5edU?utm_source=generator", title: "Playlist 6" },
  { src: "https://open.spotify.com/embed/playlist/2Fvn4fJQk787asZfpRehCi?utm_source=generator", title: "Playlist 7" },
  { src: "https://open.spotify.com/embed/playlist/4Vm8VmSbjeY3hNWvenJm0u?utm_source=generator", title: "Playlist 8" },
];

const LandingSpotify = ({language}) => {
  const sliderRef = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,  // Un seul élément affiché au centre
    slidesToScroll: 1,
    centerMode: true,  // Mode centré
    centerPadding: '20%',  // Espacement de 20% des éléments adjacents
    focusOnSelect: true,  // Permet de sélectionner un élément au clic
  };

  const handleMouseDown = (e) => {
    const startX = e.pageX - sliderRef.current.offsetLeft;
    const scrollLeft = sliderRef.current.scrollLeft;

    const handleMouseMove = (e) => {
      const x = e.pageX - sliderRef.current.offsetLeft;
      const walk = (x - startX) * 2; // Le facteur de vitesse
      sliderRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
      sliderRef.current.removeEventListener('mousemove', handleMouseMove);
      sliderRef.current.removeEventListener('mouseup', handleMouseUp);
      sliderRef.current.removeEventListener('mouseleave', handleMouseUp);
    };

    sliderRef.current.addEventListener('mousemove', handleMouseMove);
    sliderRef.current.addEventListener('mouseup', handleMouseUp);
    sliderRef.current.addEventListener('mouseleave', handleMouseUp);
  };

  const handleWheel = (e) => {
    e.preventDefault();
    sliderRef.current.scrollLeft += e.deltaY * 0.5; // Ajustez ce facteur selon vos besoins
  };

  useEffect(() => {
    const slider = sliderRef.current;
    slider.addEventListener('wheel', handleWheel);

    return () => {
      slider.removeEventListener('wheel', handleWheel);
    };
  }, []);

  return (
    <div className='bigCarouselSpotifyLanding' ref={sliderRef} onMouseDown={handleMouseDown}>
      <Slider {...settings}>
        {playlists.map((playlist, index) => (
          <div className='zizoulamoula' key={index}>
            <div className='slide'>
              <iframe
                src={playlist.src}
                width="100%"
                height="100%"
                frameBorder="0"
                allow="encrypted-media"
                title={playlist.title}
                style={{ display: 'block', margin: '0 auto' }}
              ></iframe>
            </div>
          </div>
        ))}
      </Slider>

      <p>{language === 'fr' ? 'Parce que chaque voyage crée un souvenir, parce qu’on se souvient d’une atmosphère, d’un parfum ou d’une chanson… revivez votre séjour en musique.' : 'Because every trip creates a memory, because we remember an atmosphere, a scent, or a song... relive your stay through music.'}</p>
    </div>
  );
};

export default LandingSpotify;
