import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './footermobile.css';
import content from '../../../content.json';
import NAVPIC1MOBILE from '../../../assets/Footer/Mobile/PHOTO1.jpg';
import NAVPIC2MOBILE from '../../../assets/Footer/Mobile/PHOTO2.jpg';
import NAVPIC3MOBILE from '../../../assets/Footer/Mobile/PHOTO3.jpg';
import NAVPIC4MOBILE from '../../../assets/Footer/Mobile/PHOTO4.jpg';

const FooterMobile = ({ changeLanguage, activeLanguage = 'fr' }) => {
  const [email, setEmail] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [isNewsletterInputVisible, setNewsletterInputVisible] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(activeLanguage);

  useEffect(() => {
    setCurrentLanguage(activeLanguage);
  }, [activeLanguage]);

  const getFooterImage = () => {
    const path = window.location.pathname;

    if (path.includes('nos-actualites')) {
      return NAVPIC3MOBILE;
    } else if (path.includes('les-hotels')) {
      return NAVPIC4MOBILE;
    } else if (path.includes('histoire')) {
      return NAVPIC2MOBILE;
    } else {
      return NAVPIC1MOBILE;
    }
  };

  const imageSrc = getFooterImage();

  const handleLanguageChange = (lang) => {
    if (lang !== currentLanguage) {
      setCurrentLanguage(lang);
      changeLanguage(lang); // Met à jour l'état de langue
      const currentPath = window.location.pathname.replace(/^(\/fr|\/en)/, ''); // Retire le préfixe de langue
      const newPath = lang === 'en' ? `/en${currentPath}` : currentPath; // Ajoute /en si la langue est anglaise

      // Redirige vers la nouvelle URL et recharge la page
      window.location.href = newPath; // Cela force le rafraîchissement de la page
    }
  };

  const handleNewsletterClick = () => {
    setNewsletterInputVisible(true);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    // Logique pour l'envoi de l'email
    console.log("Email soumis:", email);
    setEmail(''); // Réinitialiser l'email après soumission
    setNewsletterInputVisible(false); // Cacher l'input après soumission
    setConfirmationMessage('Votre mail est bien enregistré.'); // Afficher le message de confirmation
  };

  // Contenu du footer en fonction de la langue active
  const footerContent = content.home[currentLanguage]?.footer || {};

  return (
    <div className='footerMobileBigContainer'>
      <div className='imageFooterMobileContainer'>
        <img className='imgFooterMobile' src={imageSrc} alt="footer" />
      </div>
      <div className='contentFooterMobileContainer'>
        <div className='ligne1'>
          {isNewsletterInputVisible ? (
            <form onSubmit={handleEmailSubmit}>
              <input
                type="email"
                name="sub-email"
                placeholder="Votre email"
                value={email}
                onChange={handleEmailChange}
                required
              />
              <button type="submit">OK</button>
            </form>
          ) : (
            <p onClick={handleNewsletterClick}>{footerContent.newsletter}</p>
          )}
          <div className='langueMobileFooter'>
            <button
              className={`fr ${currentLanguage === 'fr' ? 'active' : ''}`}
              onClick={() => handleLanguageChange('fr')}
            >
              FR
            </button>
            <button
              className={`${currentLanguage === 'en' ? 'active' : ''}`}
              onClick={() => handleLanguageChange('en')}
            >
              EN
            </button>
          </div>
        </div>

        {confirmationMessage && (
          <div className='confirmationMessage'>
            <p>{confirmationMessage}</p>
          </div>
        )}

        <div className='ligne2'>
          <Link to="https://careers.werecruit.io/fr/chapitre-six">{footerContent.recrutement}</Link>
          <Link to='https://www.instagram.com/chapitresix_hotels'>{footerContent.instagram}</Link>
        </div>

        <div className='ligne3'>
          <Link to='mailto:contact@chapitresix.com'>{footerContent.contact}</Link>
          <Link to='https://linkedin.com/company/chapitre-six-hotels'>{footerContent.linkedin}</Link>
        </div>

        <div className='ligne4'>
          <p>{footerContent.mentions}</p>
          <Link to='https://www.facebook.com/chapitresixhotels/'>{footerContent.facebook}</Link>
        </div>
      </div>
    </div>
  );
};

export default FooterMobile;
