import React, { useEffect, useRef } from 'react';
import './actualitylanding.css';
import content from '../../content.json';
import LANDING from '../../assets/Actualites/PHOTOPRINCIPALE.jpg';
import ARTICLE1 from '../../assets/Actualites/ACTUALITE3.jpg';
import ARTICLE2 from '../../assets/Actualites/ACTUALITE4.jpg';
import ARTICLE3 from '../../assets/Actualites/ACTUALITE6.jpg';
import ARTICLE4 from '../../assets/Actualites/ACTUALITE2.jpg';
import ARTICLE5 from '../../assets/Actualites/ACTUALITE15.jpg';
import ARTICLE6 from '../../assets/Actualites/ACTUALITE17.jpg';
import ARTICLE7 from '../../assets/Actualites/ACTUALITE18.jpg';
import ARTICLE8 from '../../assets/Actualites/ACTUALITE7.jpg';
import ARTICLE9 from '../../assets/Actualites/CADETCOMEDYCLUB.jpg';
import ARTICLE10 from '../../assets/Actualites/ACTUALITE19.jpg';
import ARTICLE11 from '../../assets/Actualites/ACTUALITE5.jpg';
import ARTICLE12 from '../../assets/Actualites/ACTUALITE16.jpg';
import ARTICLE13 from '../../assets/Actualites/ACTUALITE9.jpg';
import ARTICLE14 from '../../assets/Actualites/ACTUALITE10.jpg';
import ARTICLE15 from '../../assets/Actualites/ACTUALITE11.jpg';
import ARTICLE16 from '../../assets/Actualites/ACTUALITE12.jpg';
import ARTICLE17 from '../../assets/Actualites/ACTUALITE13.jpg';
import { useFilter } from '../../FilterContext';

const ActualityLanding = ({ language }) => {
  const { selectedFilter } = useFilter();
  const [currentImage, setCurrentImage] = React.useState(LANDING);
  const scrollableRef = useRef(null);

  // Sécurisation de l'accès aux articles
  const actualitesContent = content.actualites[language] || {};
  const articles = [
    { ...actualitesContent.actualite1, image: ARTICLE1 },
    { ...actualitesContent.actualite2, image: ARTICLE2 },
    { ...actualitesContent.actualite3, image: ARTICLE3 },
    { ...actualitesContent.actualite4, image: ARTICLE4 },
    { ...actualitesContent.actualite5, image: ARTICLE5 },
    { ...actualitesContent.actualite6, image: ARTICLE6 },
    { ...actualitesContent.actualite7, image: ARTICLE7 },
    { ...actualitesContent.actualite8, image: ARTICLE8 },
    { ...actualitesContent.actualite9, image: ARTICLE9 },
    { ...actualitesContent.actualite10, image: ARTICLE10 },
    { ...actualitesContent.actualite11, image: ARTICLE11 },
    { ...actualitesContent.actualite12, image: ARTICLE12 },
    { ...actualitesContent.actualite13, image: ARTICLE13 },
    { ...actualitesContent.actualite14, image: ARTICLE14 },
    { ...actualitesContent.actualite15, image: ARTICLE15 },
    { ...actualitesContent.actualite15, image: ARTICLE16 },
    { ...actualitesContent.actualite15, image: ARTICLE17 },
  ];

  const filteredArticles = articles.filter(article =>
    selectedFilter === 'Tous' || article.class === selectedFilter.toLowerCase()
  );

  const handleScroll = (event) => {
    event.stopPropagation(); // Empêche le scroll de remonter à la page
  };

  useEffect(() => {
    const scrollableElement = scrollableRef.current;
    if (scrollableElement) {
      scrollableElement.addEventListener('wheel', handleScroll, { passive: false });
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener('wheel', handleScroll);
      }
    };
  }, []);

  return (
    <div className='actualityLandingContainer'>
      <div className='actualityLandingSmallContainer'>
        <div className='separateContainer'>
          <div className='actualityLandingTextBlock'>
            <h1>{actualitesContent.bloctext?.title || 'Titre par défaut'}</h1>
            <p>{actualitesContent.bloctext?.text || 'Texte par défaut'}</p>
            <p className='subTexte'>{actualitesContent.bloctext?.subtext || 'Sous-texte par défaut'}</p>
          </div>

          <div className='actualityLandingActualiteContainer'>
            <div className='scrollableContent' ref={scrollableRef}>
              {filteredArticles.length > 0 ? (
                filteredArticles.map((article, index) => (
                  <a
                    key={index}
                    href={article.url} // Utilise l'URL spécifique de chaque article
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`actualityLandingUniqueContainer ${article.class}`}
                    onMouseEnter={() => setCurrentImage(article.image)}
                    onMouseLeave={() => setCurrentImage(LANDING)}
                  >
                    <h2>{article.titre || 'Titre article par défaut'}</h2>
                    <p className='date'>{article.date || 'Date non spécifiée'}</p>
                    <p>{article.texte || 'Texte article par défaut'}</p>
                  </a>
                ))
              ) : (
                <p>Aucun article trouvé</p>
              )}
            </div>
          </div>
        </div>

        <div className='testactuality'>
          <img src={currentImage} alt="Actualité" className='actualityImgLanding' />
        </div>
      </div>
    </div>
  );
};

export default ActualityLanding;
