import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import HotelsLanding from './HotelsLanding/HotelsLanding';
import MobileLandingHotels from './mobile/MobileLandingHotels/MobileLandingHotels';
const Hotels = ({ content, langue }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <Helmet>
        <title>Chapitre Six Hotels | Une collection de maisons coups de cœur</title>
        <meta name="description" content="Chapitre Six Hotels raconte l’art de recevoir avec élégance.. Chaque jour se tissent des histoires, se vivent des aventures, redessinant les codes du passé au présent." />
      </Helmet>
      <section className="landing-section section">
        {windowWidth > 1024 ? (
          <HotelsLanding content={content} langue={langue} /> // Passe la prop 'langue'
        ) : (
          <MobileLandingHotels content={content} />
        )}
      </section>
    </div>
  );
};

export default Hotels;
