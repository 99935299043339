import React from 'react';
import { useLocation } from 'react-router-dom';
import './bookingbutton.css';

const BookingButton = ({ className, onClick, language }) => {
  const location = useLocation();

  // Vérifie si l'URL contient "/la-musique" ou "/histoire"
  const isOnSpecialPage = location.pathname.includes('/la-musique') || location.pathname.includes('/histoire');

  return (
    <button
      className={`reserve-button ${className} ${isOnSpecialPage ? 'issou' : ''}`}
      onClick={onClick}  // Le onClick ouvre le module
    >
      <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L8 11L11.5 6L15 1" stroke="#FEFAF1" />
      </svg>
      {language === 'fr' ? 'Réserver' : 'book'}
    </button>
  );
};

export default BookingButton;
